import React, { Fragment } from "react"
// import { Styled } from "theme-ui"

/**
 * Change the content to add your own bio
 */

export default () => (
  <Fragment>
    我爱你， QYY
    <br />
    要一直爱下去
  </Fragment>
)
